import { Link } from "gatsby";
import React, { CSSProperties } from "react";
import { PageLayout } from "../components/PageLayout";
import { fourOhFourPageHeroImage } from "../images";

const styles: Record<string, CSSProperties> = {
  outerContainer: {
    display: "flex",
    justifyContent: "center",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sorryHeading: {
    marginBottom: "3rem",
  },
  needHelp: {
    margin: 0,
    lineHeight: "1.5em",
    marginBottom: "1.5rem",
  },
};

const FourOhFourPage: React.FC = () => {
  return (
    <PageLayout heroBackgroundImage={`url(${fourOhFourPageHeroImage})`}>
      <div style={styles.outerContainer}>
        <div style={styles.innerContainer}>
          <h1>Page not found</h1>
          <h2 style={styles.sorryHeading}>
            <em>Sorry</em> &mdash; we couldn&rsquo;t find what you were looking
            for.
          </h2>
          <p style={styles.needHelp}>
            <strong>Need help finding something?</strong>
            <br />
            Email{" "}
            <a href="mailto:Info@TwinOakSolutions.com" target="_email">
              Info@TwinOakSolutions.com
            </a>{" "}
            and we&rsquo;ll help you.
          </p>
          <p>
            <Link to="/">Twin Oak Solutions Homepage</Link>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export default FourOhFourPage;
